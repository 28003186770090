.image-con{
    margin: 0 auto;
    max-height: 200px;
    overflow: hidden;
    width: 100%;
}
.image-hover:hover{
    transform: scale(1.5);
    width: 100%;
}
.image-hover{
    transition: 1.5s;
    width: 100%;
}


.container {
    overflow: hidden;
  }
  .slider {
    animation: slidein 12s linear infinite;
    white-space: nowrap;
  }
  .logos {
    width: 100%;
    display: inline-block;
    margin: 0px 0;
    margin-left: 20%;
  }
  .fab {
    width: calc(100% / 5);
    animation: fade-in 0.5s 
      cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
    margin-left: 60px;
  }
  @media screen and (max-width: 768px) {
    .fab img{
      height: 60px;
      width: 60px;
    }
  }
  @keyframes slidein {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  